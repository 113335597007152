/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { CancelTokenSource } from 'axios';
import { IOnboardingPayload } from '../../../app/onboarding/types/onboarding-payload.interface';
import Utils from '../../../app/utils/utils.common';
import { IReferrerData, IUserData } from './user-data.interface';
import { IUserSubScription } from './user-subscription.interface';
import { IUserReferrersReferrals } from './user-referrerReferrals.interface';
import { apiV2Instance } from '../../lib/api-v2.client';
import { HttpException } from '../../exceptions/http.exception';

export enum ReferOptions {
  SUGGESTED = 'suggested',
  SEARCH = 'search',
  QR_CODE = 'qrcode',
  SOCIAL_MEDIA = 'social-media',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  SNAPCHAT = 'snapchat',
}

export interface UserSearchResult {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
  keycloakUserId: string;
}

export interface IUserDataResponse {
  user: IUserData;
  userSubscription: IUserSubScription;
  diffDays: number;
  referrerReferrals: IUserReferrersReferrals;
}

let refererSearchCancelToken: CancelTokenSource;

export class UserService {
  async getUserData(silent = false, token?: string) {
    try {
      const response = await apiV2Instance.get(
        '/api/v2/user/sync?platformType=web',
        {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
          silent,
        },
      );
      const { message, status } = response.data;
      const result: IUserDataResponse = response.data?.result;
      if (!result || status !== 'success') {
        throw new HttpException(message);
      }
      const { user, ...restResult } = result;
      const { userStateFlags, ...restUser } = user;
      userStateFlags.sort((a, b) => (a.stateCode < b.stateCode ? -1 : 1));
      const userData = {
        user: {
          userStateFlags,
          ...restUser,
        },
        ...restResult,
      };
      return userData;
    } catch (error) {
      console.error(error);
      const { data } = error;
      throw new HttpException(data?.message);
    }
  }

  setUserStateFlagShow({ isShowFlags }: { isShowFlags: boolean }) {
    return apiV2Instance
      .post('/api/user/setUserStateFlag', { isShowFlags })
      .then(response => {
        const { status, message } = response.data;
        if (status !== 'success') {
          throw new Error(message);
        }
      });
  }

  completeOnboarding() {
    return apiV2Instance
      .post('/api/user/onboarding/complete')
      .then(response => response.data);
  }

  searchUser(searchText: string): Promise<IUserData[] | undefined> {
    if (refererSearchCancelToken) {
      refererSearchCancelToken.cancel('Operation canceled due to new request.');
    }
    refererSearchCancelToken = axios.CancelToken.source();
    return apiV2Instance
      .get('/api/user/search', {
        params: {
          searchString: `${searchText}`,
        },
        cancelToken: refererSearchCancelToken.token,
        silent: true,
      })
      .then(response => (response ? response.data ?? [] : undefined));
  }

  getAllUsers(): Promise<IUserData[] | undefined> {
    if (refererSearchCancelToken) {
      refererSearchCancelToken.cancel('Operation canceled due to new request.');
    }
    refererSearchCancelToken = axios.CancelToken.source();
    return apiV2Instance
      .get('/api/user/all', {
        cancelToken: refererSearchCancelToken.token,
        silent: true,
      })
      .then(response => (response ? response.data ?? [] : undefined));
  }

  async updateReferrer(payload: {
    referrerId: string;
    referralFrom: ReferOptions;
    referPage?: string;
  }): Promise<any> {
    const response = await apiV2Instance.post('/api/connections', {
      connectionId: payload.referrerId,
      isReferer: true,
      meta: {
        referralFrom: payload.referralFrom || ReferOptions.SEARCH,
        referPage: payload.referPage || 'referral',
      },
    });
    return response.data?.result ?? [];
  }

  // eslint-disable-next-line consistent-return
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateProfileDetails(payload: Partial<IOnboardingPayload>) {
    try {
      const data = new FormData();
      if (payload.accountType) data.append('accountType', payload.accountType);
      if (payload.firstName) data.append('firstName', payload.firstName);
      if (payload.lastName) data.append('lastName', payload.lastName);
      if (payload.accountSubType)
        data.append('accountSubType', JSON.stringify(payload.accountSubType));
      if (payload.publicDisplayName)
        data.append('publicDisplayName', payload.publicDisplayName);
      if (payload.privateDisplayName)
        data.append('privateDisplayName', payload.privateDisplayName);
      if (payload.referralQuoteMessage)
        data.append('referralQuoteMessage', payload.referralQuoteMessage);
      if (payload.isAllowAffiliate)
        data.append(
          'isAllowAffiliate',
          payload.isAllowAffiliate ? 'true' : 'false',
        );
      if (payload.referralRelations?.length) {
        data.append(
          'referralRelations',
          JSON.stringify(payload.referralRelations),
        );
      }
      if (payload.channelDisplayName) {
        data.append('channelDisplayName', payload.channelDisplayName);
      }
      if (payload.profileCard) {
        data.append('profileCard', payload.profileCard);
      }
      if (typeof payload.isOnboardingCompleted === 'boolean') {
        console.log('When this chagnes: ', payload.isOnboardingCompleted);
        data.append(
          'isOnboardingCompleted',
          `${payload.isOnboardingCompleted}`,
        );
      }
      if (payload.referralFrom) {
        data.append('referralFrom', `${payload.referralFrom}`);
      }

      return apiV2Instance
        .put('/api/v2/user/updateProfileDetails', data)
        .then(response => response.data?.result ?? []);
    } catch (error) {
      console.error('updateProfileDetails', error);
      const { data } = error;
      throw new HttpException(data?.message);
    }
  }

  async uploadProfilePicture(imgBase64: string) {
    const data = new FormData();
    const file = await Utils.base64ToImageFile(imgBase64, 'profile.png');
    data.append('image', file);
    return apiV2Instance.put('/api/v2/user/updateProfile', data).catch(() => {
      // eslint-disable-next-line no-alert
      alert('Error occurred while updating profile picture!');
    });
  }

  visitUserReferralPage(
    channelId: string,
    refCode?: string,
    startTimer?: boolean,
  ): Promise<IReferrerData> {
    return apiV2Instance
      .get(`/api/user/visit/${channelId}`, {
        params: {
          refCode,
          startTimer,
        },
      })
      .then(response => response.data);
  }

  async updateAccountLabel(accountLabel: string): Promise<IReferrerData> {
    const response = await apiV2Instance.post(`/api/user/updateAccountLabel`, {
      accountLabel,
    });
    const { status, message, data } = response.data;
    if (status !== 'success') {
      throw new Error(message);
    }
    return data;
  }

  async getAccountLabel(): Promise<IReferrerData> {
    const response = await apiV2Instance.post(`/api/user/account_label`, {});
    const { status, message, data } = response.data;
    if (status !== 'success') {
      throw new Error(message);
    }
    return data;
  }

  getUserByChannelId(channelId: string): Promise<IReferrerData> {
    return apiV2Instance
      .get(`/api/user/${channelId}`)
      .then(response => response.data?.data);
  }

  async fetchNotifications() {
    return apiV2Instance
      .get('/api/notifications?page=1&limit=100', { silent: true })
      .then((response: { data: any }) => {
        const result: any[] = response.data?.data?.notifications ?? [];
        return result;
      });
  }

  async fetchNotificationsCount() {
    return apiV2Instance
      .get('/api/notifications/unread/count', {
        silent: true,
      })
      .then(response => {
        const result: { count: number } = response.data?.data ?? { count: 0 };
        return result;
      });
  }

  async readNotification(notificationId: string) {
    return apiV2Instance
      .patch(
        `/api/notifications/${notificationId}`,
        { isRead: true },
        { silent: true },
      )
      .then(response => {
        const result: any = response;
        if (!result) {
          throw new Error('Reading notification error!');
        }
        return result.data.notifications;
      });
  }

  async fetchRefererralNotifications() {
    return apiV2Instance
      .get('/api/user/getReferralNotification', { silent: true })
      .then(response => {
        const result: any = response.data?.result;

        if (!result) {
          throw new Error('Notifications not found!');
        }
        return result;
      });
  }

  setRefererralNotifications(data: any) {
    return apiV2Instance
      .post('/api/user/setReferralNotification', data, { silent: true })
      .then(response => {
        const { status, message } = response.data;
        if (status !== 'success') {
          throw new Error(message);
        }
      });
  }

  async addSocialConnection(socialMeta: {
    socialPlatform: string;
    socialId: string;
    socialUsername: string;
  }) {
    try {
      const { data } = await apiV2Instance.post(
        '/api/user-social-connection',
        socialMeta,
        { silent: true },
      );
      return data;
    } catch (error) {
      return null;
    }
  }

  async getUserDetails(userId: string) {
    try {
      const result = await apiV2Instance.get(`/api/user/${userId}`, {
        silent: true,
      });
      return result.data.data;
    } catch (error) {
      return null;
    }
  }

  async getUserReferrer(channelName: string) {
    try {
      const result = await apiV2Instance.get(
        `/api/connections?connectionType=referrers&channelName=${channelName}`,
        { silent: true },
      );
      return result.data.data;
    } catch (error) {
      return null;
    }
  }

  async getUserReferrals(channelName: string, page: number, limit: number) {
    try {
      const result = await apiV2Instance.get(
        `/api/connections?connectionType=referrals&channelName=${channelName}&page=${page}&limit=${limit}`,
        { silent: true },
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getUserFavorites(channelName: string, page: number, limit: number) {
    try {
      const result = await apiV2Instance.get(
        `/api/connections?connectionType=favorites&channelName=${channelName}&page=${page}&limit=${limit}`,
        { silent: true },
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getUserFollowers(channelName: string, page: number, limit: number) {
    try {
      const result = await apiV2Instance.get(
        `/api/connections?connectionType=followers&channelName=${channelName}&page=${page}&limit=${limit}`,
        { silent: true },
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async getUserFollowing(channelName: string, page: number, limit: number) {
    try {
      const result = await apiV2Instance.get(
        `/api/connections?connectionType=following&channelName=${channelName}&page=${page}&limit=${limit}`,
        { silent: true },
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async fetchAdPreferences() {
    return apiV2Instance
      .get('/api/v2/user/advertisement/preferences', { silent: true })
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async addAdPreferences(dataObj: any) {
    return apiV2Instance
      .put('/api/v2/user/advertisement/preferences', dataObj, { silent: true })
      .then(response => {
        const result: any = response;
        if (!result) {
          throw new Error('add Advertisement error!');
        }
        return result.data;
      });
  }

  async updateEmailAddressSettings(newEmail: string) {
    return apiV2Instance
      .put('/api/user/settings/update-email', { email: newEmail })
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async updateUserPassword(newPassword: string) {
    return apiV2Instance
      .put('/api/user/keycloak/update-password', {
        newPassword,
      })
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async hardResetPassword(payload: any) {
    return apiV2Instance
      .put('/api/user/keycloak/hard-reset-password', payload)
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async updateUserLocation(cityName: string, countryCode: string) {
    return apiV2Instance
      .put('/api/user/update-location', {
        cityName,
        countryCode,
      })
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async updateEmailAddressKeycloak(accessToken: string) {
    return apiV2Instance
      .put('/api/user/keycloak/change-email', {
        accessToken,
      })
      .then(response => {
        const result: any = response?.data?.result;
        return result;
      });
  }

  async getGoogleProfile() {
    try {
      const response = await apiV2Instance.get('/api/user/profile/google');
      const result = response.data || {};
      return result;
    } catch (error) {
      return {};
    }
  }

  async getDefaultChannels() {
    try {
      const response = await apiV2Instance.get(
        '/api/user/search?action=default',
        {},
      );
      const result = response.data || {};
      return result;
    } catch (error) {
      return {};
    }
  }

  async deleteUser() {
    try {
      const response = await apiV2Instance.delete('/api/user/me');
      const result = response.data || {};
      return result;
    } catch (error) {
      return null;
    }
  }

  async makeAdmin() {
    try {
      const response = await apiV2Instance.post('/api/user/admin');
      const result = response.data || {};
      return result;
    } catch (error) {
      return null;
    }
  }

  async makeAdminAsPer(channelName: string) {
    try {
      const response = await apiV2Instance.post('/api/user/admin', {
        channelName,
      });
      const result = response.data || {};
      return result;
    } catch (error) {
      return null;
    }
  }

  async deleteAnotherUser(user: any) {
    try {
      const response = await apiV2Instance.post('/api/user/delete', {
        user,
      });
      const result = response.data || {};
      return result;
    } catch (error) {
      return null;
    }
  }

  async getTestimonials() {
    return apiV2Instance
      .get(`/api/testimonials`)
      .then(response => response.data.data);
  }

  async getFaqs() {
    try {
      return apiV2Instance.get(`/api/faq`).then(response => response.data.data);
    } catch (error) {
      return null;
    }
  }

  async getSubscriptionPlans() {
    return apiV2Instance
      .get(`/api/public/subscription`)
      .then(response => response.data);
  }
}
